.header {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5rem;
  background-color: #1f2f48;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;

  @media (max-width: 980px) {
    height: 4rem;
    background: #1f2f48;
  }
}

.logo {
  margin: 0 1rem;
  width: 18rem;

  @media (max-width: 980px) {
    margin: 0 1rem;
    width: 12rem;
  }
}

.unHiperLinkStyle {
  text-align: center;
  text-decoration: none;
  color: inherit;
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;

  margin: 0 1rem;
  font-size: 1.1rem;
  color: #ffffff;

  :hover {
    font-weight: 500;
    border-bottom: 1px solid;
  }
}

.boldLink {
  font-weight: 500;
  border-bottom: 2px solid;
}

// 모바일

.iconBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin: 0 1rem 0 0;

  .callIcon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .menuIcon {
    width: 2rem;
    height: 2rem;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 100;

  .closeButton {
    position: absolute;
    top: 1.5rem;
    right: 0.5rem;
    z-index: 3;
  }
}

.MobileMenu {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;
  padding: 2rem 2rem 0 1.5rem;
  width: 5rem;
  height: 100vh;
  background-color: #1f2f48;
  color: #ffffff;
  list-style-type: none;
  z-index: 2;

  .item {
    font-size: 1rem;
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
  }
}

//부정클릭 박스

.redBox {
  position: absolute;
  top: 50%;
  left: 20rem;
  transform: translateY(-50%);
  padding: 0.5rem;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-size: 1rem;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  border-radius: 0.5rem;
  background-color: #ff0000;
  // border: 1px solid #ff0000;

  animation-name: twinkling;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @media (max-width: 1120px) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ff0000;
    // border: 1px solid #ff0000;
  }

  @media (max-width: 980px) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @keyframes twinkling {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

// 헤더 변경 style
.addContainer {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
  gap: 1rem;
  margin: 0 0 0 1rem;

  .text1 {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    gap: 0.2rem;
    color: #320CAC;
    text-decoration: none;

    .text11 {
      padding: 0.2rem 0.5rem;
      background-color: #320CAC;
      border-radius: 0.2rem;

      color: #FFFFFF;
      font-size: 0.8rem;
      font-weight: 500;
    }

    .text12 {
      padding: 0.5rem;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }


  .text3 {
    color: #320CAC;
    text-decoration: none;
    border: 1px solid #320CAC;
    border-radius: 10rem;

    font-size: 0.8rem;
    padding: 0.2rem 1rem;
  }
}