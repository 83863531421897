// 평형 선택
.buttonBox {
    margin: 0.5rem;
    width: 5rem;
    height: 2rem;
    font-size: 1rem;
    border-radius: 0.2rem;
}

.noClickedbuttonBox {
    font-family: "Noto Sans KR", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #002b71;
    border: none;
}

.clickedButton {
    font-family: "Noto Sans KR", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    text-align: center;
    background-color: rgbargb(0, 43, 113, 0.1);
    color: #002b71;
    border: 2px solid #002b71;
}

// 방문예약 상담예약 아래 고정 버튼
.fixedButtonBox {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    font-size: 1.2rem;
    font-family: "Noto Sans KR", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;

    .visitedButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem 0;
        width: 100%;
        color: #FFFFFF;
        text-align: center;
        text-decoration: none;
        background-color: #1f2f48;
    }

    .callButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem 0;
        width: 100%;
        color: #FFFFFF;
        text-decoration: none;
        background-color: #5d5d5d;
    }
}