.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 15rem;
    padding-top: 5.5rem;

    @media (max-width: 980px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .image {
        width: 100%;

        @media (max-width: 980px) {
            width: 100vw;
        }
    }
}