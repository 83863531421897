.webContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.floor1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;

    img {
        height: 18rem;
    }
}

.floor2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;

    .floor2Left {
        width: 36.8rem;
        height: 21rem;

    }

    .floor2Right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;

        img {
            height: 10.3rem;
        }

        .menu {
            height: 12vw;
        }
    }
}



.mobileContainer {
    width: 100vw;

    img {
        width: 100%;
    }
}

.mobileFloor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}