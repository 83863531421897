.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 15rem;
    padding-top: 5.5rem;

    @media (max-width: 980px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .image {
        width: 100%;

        @media (max-width: 980px) {
            width: 100vw;
        }
    }
}

.buttonBoxTitle {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    font-optical-sizing: auto;
    font-style: normal;
    text-align: center;
    font-size: 1.7rem;
    margin: 1rem;
}

.buttonBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 0 2rem 0;
}