.modal1 {
  position: fixed;
  top: 50%;
  left: 20%;
  transform: translate(-20%, -50%);
  display: flex;
  flex-direction: column;

  border: 1px solid #000000;
  z-index: 1000;

  .img{
    width: 25rem;
    height: auto;

    @media (max-width: 980px) {
      width: 15rem;
      height: auto;
    }
  }

  .closeLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;

    @media (max-width: 980px) {
      font-size: 0.8rem;
    }
  }
}

.modal2 {
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-60%, -50%);
  display: flex;
  flex-direction: column;

  border: 1px solid #000000;
  z-index: 1000;

  .img{
    width: 25rem;
    height: auto;

    @media (max-width: 980px) {
      width: 15rem;
      height: auto;
    }
  }
  
  .closeLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;

    @media (max-width: 980px) {
      font-size: 0.8rem;
    }
  }
}

.clonseBtn {
  font-weight: bold;
  border: none;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
  
  @media (max-width: 980px) {
    font-size: 0.8rem;    
  }
}

img {
  @media (max-width: 980px) {
    width: 15rem;
  }
}